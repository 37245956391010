import React from "react";
import Layout from "../components/layout";
import Banner from "../components/banner";
import SEO from "../components/seo";
import Welcome from "../components/welcome";
import HeadingBlock from "../components/headingBlock";
import { Container, Col, Row, Carousel } from "react-bootstrap";
import { StaticQuery, graphql, Link } from "gatsby";
import NewsBox from "../components/newsBox";

import SlideOneImg from "../assets/images/resources/trans-101.jpg";
import SlideTwoImg from "../assets/images/resources/inculcation-of-equality.jpg";
import SlideThreeImg from "../assets/images/resources/celebrating-our-successes.jpg";
import SlideFourImg from "../assets/images/resources/quality-improvement.jpg";
import SlideFiveImg from "../assets/images/resources/making-sustainability.jpg";
import SlideSixImg from "../assets/images/resources/restoring-our-city.jpg";
import SlideSevenImg from "../assets/images/resources/data-collection.jpg";

import NewsSEOImage from "../assets/images/banner-homepage.jpg";
import ExternalLink from "../components/ExternalLink";

function handlePosts(edges, numArticles) {
  const NewsArticles = edges
    .filter((edge, i) => !!edge.node.frontmatter.date && i < numArticles)
    .map((edge) => <NewsBox key={edge.node.id} post={edge.node} />);

  return <div id='news'>{NewsArticles}</div>;
}

class Insights extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMoreArticles: false };
  }

  render() {
    return (
      <>
        <Layout page='publications'>
          <SEO
            title='Insights'
            description='Read the latest insights from the Safer Childbirth Cities initiative. Grantees across the U.S. are sharing their impact and how they are powering progress for maternal health and birth equity.'
            image={NewsSEOImage}
            keywords={[]}
          />
          <Banner
            title='Insights'
            subTitle={`Read the latest insights from the Safer Childbirth Cities initiative. Grantees across the U.S. are sharing their impact and how they are powering progress for maternal health and birth equity.`}
            titleColor='#FFFFFF'
            subTitleColor='#FFFFFF'
            hasArrow={false}
            reverse={true}
          />
          <HeadingBlock title='Community of Practice Learnings' light className='insightsNewsHeading' />
          <Container>
            <Row>
              <Col lg='12'>
                <Carousel className='resources-carousel'>
                  <Carousel.Item>
                    <img className='d-block w-100' src={SlideSevenImg} alt='Second slide' />
                    <Carousel.Caption>
                      <h3>Health Connect One - Data Collection For Change</h3>
                      <p>October 26, 2022</p>
                    </Carousel.Caption>
                  </Carousel.Item>

                  <Carousel.Item>
                    <img className='d-block w-100' src={SlideSixImg} alt='Second slide' />
                    <Carousel.Caption>
                      <h3>Safer Childbirth Cities Initiative - San Francisco, Sisterweb's Story</h3>
                      <p>August 24, 2022</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className='d-block w-100' src={SlideFiveImg} alt='Second slide' />
                    <Carousel.Caption>
                      <h3>Safer Childbirth Cities Initiative - Pittsburgh, Making Sustainability a Homerun</h3>
                      <p>August 23, 2022</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className='d-block w-100' src={SlideFourImg} alt='Second slide' />
                    <Carousel.Caption>
                      <h3>Safer Childbirth Cities Initiative - Chicago, Community Engagement</h3>
                      <p>August 23, 2022</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className='d-block w-100' src={SlideThreeImg} alt='Second slide' />
                    <Carousel.Caption>
                      <h3>Safer Childbirth Cities Initiative - Celebrating Successes</h3>
                      <p>August 23, 2022</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className='d-block w-100' src={SlideTwoImg} alt='Second slide' />
                    <Carousel.Caption>
                      <h3>Alliance for Innovation on Maternal Health - Community Care Initiative</h3>
                      <p>August 23, 2022</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className='d-block w-100' src={SlideOneImg} alt='First slide' />
                    <Carousel.Caption>
                      <h3>Trans Birthing Experience - Kayden Coleman</h3>
                      <p>July 27, 2022</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </Col>
            </Row>
          </Container>
          <HeadingBlock title='News' light={true} />
          <StaticQuery
            query={graphql`
              query NewsMeta {
                site {
                  siteMetadata {
                    title
                    description
                  }
                }
                allMarkdownRemark(filter: { frontmatter: { key: { eq: "article" } } }, sort: { order: DESC, fields: [frontmatter___date] }) {
                  edges {
                    node {
                      id
                      excerpt(pruneLength: 250)
                      frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        slug
                        title
                        featuredImage {
                          childImageSharp {
                            fluid(maxWidth: 800) {
                              ...GatsbyImageSharpFluid
                            }
                          }
                        }
                        redirect
                      }
                    }
                  }
                }
              }
            `}
            render={(data) => (
              <>
                {handlePosts(data.allMarkdownRemark.edges, this.state.showMoreArticles ? data.allMarkdownRemark.edges.length : 4)}
                <button
                  className='btn btn-inverse large center-block'
                  style={{ margin: "30px auto", display: "block" }}
                  onClick={() => this.setState({ showMoreArticles: !this.state.showMoreArticles })}
                >
                  {this.state.showMoreArticles ? "SHOW LESS" : "SHOW MORE"}
                </button>
              </>
            )}
          />
          <HeadingBlock title='Publications' light={true} />
          <Container className='publications-container'>
            <div className='spacer#20' />

            <h2 className='publications-location'>San Francisco</h2>
            <Row className='publication'>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <ExternalLink href='https://www.sciencedirect.com/science/article/pii/S2667321523000434/'>
                  <a>
                    “An extra layer of pressure to be my best self”: Healthcare provider perspectives on how doulas foster accountability and bridge
                    gaps in pregnancy-related care.
                  </a>
                </ExternalLink>
                <p>
                  Reiley Reed, Ashley Nguyen, Marna Armstead, Alli Cuentos, Marlee-I Mystic, Jennet Arcara Andrea V. Jackson, Cassondra Marshall, Anu
                  Manchikanti Gomez. June 2023.
                </p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <ExternalLink href='https://www.sciencedirect.com/science/article/pii/S2667321523000434/'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </ExternalLink>
              </Col>
            </Row>

            <h2 className='publications-location'>Camden</h2>
            <Row className='publication'>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <ExternalLink href='https://camdenhealth.org/wp-content/uploads/2023/03/Safer-Cities-snapshot.pdf'>
                  <a>Pregnancy care initiation pilot – Demonstration snapshot.</a>
                </ExternalLink>
                <p>Camden Coalition. March 2023.</p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <ExternalLink href='https://camdenhealth.org/wp-content/uploads/2023/03/Safer-Cities-snapshot.pdf'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </ExternalLink>
              </Col>
            </Row>

            <h2 className='publications-location'>Chicago</h2>
            <Row className='publication'>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <a href='/publications/CCMH-Policy-Brief_101522_FINAL-c.pdf' target='_blank'>
                  Chicago Collaborative for Maternal Health Policy Brief.
                </a>

                <p>Kevie Lusby Smyre, Eva Alonso. October 2022.</p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <a href='/publications/CCMH-Policy-Brief_101522_FINAL-c.pdf' target='_blank'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </a>
              </Col>
            </Row>

            <h2 className='publications-location'>St. Louis</h2>
            <Row className='publication'>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <ExternalLink href='https://cpb-us-w2.wpmucdn.com/sites.wustl.edu/dist/1/2391/files/2022/04/Medicaid-Reimbursement-for-Doula-Services-April-2022-1.pdf'>
                  <a>Medicaid Reimbursement for Doula Services: Definitions and Policy Considerations.</a>
                </ExternalLink>

                <p>Ethan Bradley, BA; Abigail R. Barker, PhD; Hakima Payne, MSN, RN; Okunsola M. Amadou, CPM; Jesse A. Davis, MD, MBA. April 2022.</p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <ExternalLink href='https://cpb-us-w2.wpmucdn.com/sites.wustl.edu/dist/1/2391/files/2022/04/Medicaid-Reimbursement-for-Doula-Services-April-2022-1.pdf'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </ExternalLink>
              </Col>
            </Row>
            <Row className={`publication ${!this.state.showMorePublications && "hide"}`}>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <ExternalLink href='https://news.stlpublicradio.org/health-science-environment/2022-04-11/birth-center-and-postpartum-retreat-in-ferguson-to-address-disparities-in-care'>
                  <a>Birth center and postpartum retreat in Ferguson to address disparities in care.</a>
                </ExternalLink>

                <p>Chad Davis. April 2022.</p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <ExternalLink href='https://news.stlpublicradio.org/health-science-environment/2022-04-11/birth-center-and-postpartum-retreat-in-ferguson-to-address-disparities-in-care'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </ExternalLink>
              </Col>
            </Row>

            <h2 className={`publications-location  ${!this.state.showMorePublications && "hide"}`}>New Orleans</h2>
            <Row className={`publication ${!this.state.showMorePublications && "hide"}`}>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <ExternalLink href='https://partnersforfamilyhealth.org/wp-content/uploads/2022/03/LA_PerinatalMentalHealthTaskForcePolicyBrief_2.8.22.pdf'>
                  <a>Louisiana Perinatal Mental Health Task Force Policy Brief.</a>
                </ExternalLink>

                <p>The Institute of Women & Ethnic Studies & The Louisiana Department of Health. February 2022.</p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <ExternalLink href='https://partnersforfamilyhealth.org/wp-content/uploads/2022/03/LA_PerinatalMentalHealthTaskForcePolicyBrief_2.8.22.pdf'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </ExternalLink>
              </Col>
            </Row>

            <h2 className={`publications-location  ${!this.state.showMorePublications && "hide"}`}>Atlanta</h2>
            <Row className={`publication ${!this.state.showMorePublications && "hide"}`}>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <ExternalLink href='https://blackmamasmatter.org/wp-content/uploads/2022/04/BMMA_AMDDReport_FINAL.pdf'>
                  <a>
                    Black Women’s and Birth Workers’ Experiences of Disrespect and Abuse in Maternity Care: Findings From a Qualitative Exploratory
                    Research Study in Atlanta, Georgia.
                  </a>
                </ExternalLink>

                <p>Black Mamas Matter Alliance. 2022.</p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <ExternalLink href='https://blackmamasmatter.org/wp-content/uploads/2022/04/BMMA_AMDDReport_FINAL.pdf'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </ExternalLink>
              </Col>
            </Row>
            <Row className={`publication ${!this.state.showMorePublications && "hide"}`}>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <ExternalLink href='https://blackmamasmatter.org/wp-content/uploads/2022/08/BMMA_MMRCReport2021_Finalv2.pdf'>
                  <a>Maternal Mortality Review Committees: Sharing Power with Communities.</a>
                </ExternalLink>

                <p>Black Mamas Matter Alliance. November 2021.</p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <ExternalLink href='https://blackmamasmatter.org/wp-content/uploads/2022/08/BMMA_MMRCReport2021_Finalv2.pdf'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </ExternalLink>
              </Col>
            </Row>

            <h2 className={`publications-location  ${!this.state.showMorePublications && "hide"}`}>Philadelphia</h2>
            <Row className={`publication ${!this.state.showMorePublications && "hide"}`}>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <ExternalLink href='https://www.phila.gov/media/20210322093837/MMRReport2020-FINAL.pdf'>
                  <a>Improving outcomes: Maternal Mortality in Philadelphia.</a>
                </ExternalLink>

                <p>The Philadelphia Department of Public Health. 2021.</p>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <ExternalLink href='https://www.phila.gov/media/20210322093837/MMRReport2020-FINAL.pdf'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </ExternalLink>
              </Col>
            </Row>

            <button
              className='btn btn-inverse large center-block'
              style={{ margin: "30px auto", display: "block" }}
              onClick={() => this.setState({ showMorePublications: !this.state.showMorePublications })}
            >
              {this.state.showMorePublications ? "SHOW LESS" : "SHOW MORE"}
            </button>
          </Container>

          <HeadingBlock title='Podcasts' light={true} />
          <Container className='publications-container'>
            <Row className='publication'>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <Link to='/podcasts/birthwork'><em>BirthWork</em>: Thriving and Striving for Better Birth Outcomes</Link>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <Link to='/podcasts/birthwork'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </Link>
              </Col>
            </Row>
            <Row className='publication'>
              <Col className='center-flex' xs='12' sm='12' md='8' lg='8'>
                <Link to='/podcasts/maternal-health-innovation'>Maternal Health Innovation</Link>
              </Col>
              <Col className='center-flex justify-center hidden-xs hidden-sm' xs='12' sm='12' md='4' lg='4'>
                <Link to='/podcasts/maternal-health-innovation'>
                  <button className='btn btn-secondary large center-block'>LEARN MORE</button>
                </Link>
              </Col>
            </Row>
          </Container>
        </Layout>
      </>
    );
  }
}

export default Insights;
