import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';

// import sccIcon from '../assets/images/scc-icon.png';

class HeadingBlock extends React.Component {
  constructor(props) {
    super(props);

    this.children = this.props.children;

    this.title = this.props.title;
    this.height = this.props.height;
    this.image = this.props.image;
    this.reverse = this.props.reverse;
    this.light = this.props.light;
  }

  render() {
    return (
      <section
        id='heading-block'
        className={`${this.image ? 'hasImg' : ''}`}
        style={{
          background: `${
            this.image
              ? `url(${this.image}) no-repeat center center`
              : this.light
              ? `#27475a`
              : `#262626`
          }`,
          backgroundSize: `cover`,
          paddingTop: `${this.height}`,
          paddingBottom: `${this.image ? '65px' : ''}`
        }}
      >
        {this.children}
        <Container>
          <Row>
            <Col className='center-flex' xs='12' sm='12' md='12' lg='12'>
              {/* <img className='icon img-responsive' src={sccIcon} alt='SCC Icon' /> */}
              <h1>{this.title}</h1>
            </Col>
          </Row>
        </Container>
        <div className={this.image ? 'arrow-block' : ''}>
          {this.image ? <div className='arrow-block-filler-small'></div> : ''}
          <div
            className={this.reverse ? `arrow-down teal` : `arrow-down`}
            style={{
              background: `${
                this.image
                  ? `url(${this.image}) no-repeat center center`
                  : this.light
                  ? `#27475a`
                  : `#262626`
              }`,
              backgroundSize: `cover`
            }}
          ></div>
          {this.image ? <div className='arrow-block-filler'></div> : ''}
        </div>
      </section>
    );
  }
}

export default HeadingBlock;
